import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ToggleSidenavService } from 'src/app/services/toggle-sidenav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { InviteComponent } from 'src/app/shared/invite/invite.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ClassSpecificService } from '../services/class-specific.service';
import { Class } from 'src/app/models/class.models';
import { PostAssignmentComponent } from '../post-assignment/post-assignment.component';
import { PostMaterialComponent } from '../post-material/post-material.component';
import { TestComponent } from '../test/test.component';
import { Posts } from 'src/app/models/post.models';
import { TableService } from 'src/app/services/table.service';
import { TestSourceAlertComponent } from 'src/app/shared/test-source-alert/test-source-alert.component';
import { UploadFromLibraryComponent } from '../upload-from-library/upload-from-library.component';
import { TestService } from '../services/test.service';
import { ScheduleLiveClassComponent } from '../schedule-live-class/schedule-live-class.component';
import { LiveClassModel } from 'src/app/models/live-class-models';
import { LIVE_CLASS_TABS } from 'src/app/Constants/Constant';
import { DateTimeService } from 'src/app/services/date-time.service';
import { LabelService } from 'src/app/services/label.service';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { USER_TYPES } from 'src/app/Constants/user-types-constants';
import { SchoolGroupService } from 'src/app/services/school-group.service';
import { AccessRights } from 'src/app/models/super-admin-users.model';

export interface actionType {
  type: string;
  post: Posts;
}

export const INVITE_STUDENTS = 'Invite Students';
export const INVITE_COTEACHERS = 'Invite Co-Teachers';
export const INVITE_POST_MATERIAL = 'Post Material';
export const INVITE_POST_ASSIGNMENT = 'Post Assignment';
export const INVITE_TAKE_TEST = 'Take a Test';
export const DIALOG_WIDTH = '91%';
export const DIALOG_LIVE_CLASS_WIDTH = '70%';
export const CREATE_LIVE_CLASS = 'Create Live Class';
const LIVE_CLASS = 'Live Class';
const POSTS = 'Posts';
const STUDENTS = 'Students';
const CO_TEACHERS = 'Co-Teachers';
@Component({
  selector: 'app-class-detail',
  templateUrl: './class-detail.component.html',
  styleUrls: ['./class-detail.component.scss']
})
export class ClassDetailComponent implements OnInit, OnDestroy {
  sortDirection: 'asc' | 'desc' = 'desc';
  selectedClass: Class;

  tabs = [{ label: LIVE_CLASS }, { label: POSTS }, { label: STUDENTS }, { label: CO_TEACHERS }];

  actionList: string[] = [INVITE_STUDENTS, INVITE_COTEACHERS, INVITE_POST_MATERIAL, INVITE_POST_ASSIGNMENT,
    INVITE_TAKE_TEST, CREATE_LIVE_CLASS];
  action = new FormControl();
  studentColumns: string[] = ['name', 'phone', 'testMarks', 'assignments', 'performance', 'status', 'actions'];
  coteacherColumns: string[] = ['name', 'phone', 'subjects', 'actions'];
  postsColumn: string[] = ['date', 'submissionDate', 'activityType', 'title', 'totalMarks', 'submittedBy', 'actions'];
  liveClassColumn: string[] = ['startsAt', 'endsAt', 'duration', 'title', 'standard', 'action'];
  studentList = [];
  coTeachers = [];
  posts = [];
  liveClass: LiveClassModel[] = [];
  classID: string;
  selectedDate = 'Today';
  public weekRange: any = {};
  private destroy$$: Subject<any> = new Subject<any>();
  config = {
    width: '46%',
    height: '100%',
    backdropClass: 'blur',
    data: null,
    disableClose: true,
    panelClass: ['teacher-theme', 'width-class'],
    autoFocus: false
  }
  labelData;
  accessRights: AccessRights;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public snackbar: SnackbarService,
    public specificClassService: ClassSpecificService,
    public dateService: DateTimeService,
    public tableService: TableService,
    public testService: TestService,
    public sidenavService: ToggleSidenavService,
    public labelService: LabelService,
    private dialogRef: MatDialog,
    private schoolGroupSvc: SchoolGroupService
  ) { }

  ngOnInit(): void {
    this.labelService.labels$.pipe(takeUntil(this.destroy$$)).subscribe(data => this.labelData = data)
    this.sidenavService.minmizeSidenav();
    this.schoolGroupSvc.accessRights$.subscribe(accessRights => {
      this.accessRights = accessRights;
      if (this.accessRights) {
        this.actionList = this.actionList.filter(action => {
          if (!this.accessRights.assignments && action === INVITE_POST_ASSIGNMENT) {
            return false;
          }
          if (!this.accessRights.materials && action === INVITE_POST_MATERIAL) {
            return false;
          }
          if (!this.accessRights.tests && action === INVITE_TAKE_TEST) {
            return false;
          }
          if (!this.accessRights.liveClasses && action === CREATE_LIVE_CLASS) {
            return false;
          }
          return true;
        });
        if (!this.accessRights.liveClasses) {
          this.tabs = this.tabs.filter(tab => tab.label !== LIVE_CLASS);
        }
      }
    });
    this.route.params.pipe(
      takeUntil(this.destroy$$)
    ).subscribe(param => {
      if (param.id) {
        this.classID = param.id;
        this.specificClassService.getClassDetail(param.id)
          .subscribe(
            (res) => {
              this.selectedClass = res;
              this.tabSelect({ index: 0 });
            },
            (err => {
              if (err.error.error.includes('Access restricted')) {
                const dialogRef = this.dialogRef.open(AlertDialogComponent, {
                  width: '363px',
                  disableClose: true,
                  height: '170px',
                  backdropClass: 'blur',
                  data: { alertText: 'Access Denied', type: 'accessdenied' },
                  panelClass: ['delete-dialog-css'],
                  autoFocus: false
                });
                dialogRef.afterClosed().subscribe(() => {
                  this.router.navigate(['../../', 'classes'], { relativeTo: this.route });
                });
              }
              else {
                this.snackbar.openSnackbar(err.error.error);
              }
            })

          );
      }
    });


    this.specificClassService.liveClass$.pipe(
      takeUntil(this.destroy$$),
    ).subscribe(
      (res) => {
        this.liveClass = res;
      },
      (err) => console.log(err)
    )

    this.action.valueChanges.pipe(
      takeUntil(this.destroy$$)
    )
      .subscribe(event => {
        let dialogConfig = { ...this.config };
        dialogConfig.data = { title: event, id: this.selectedClass.id.toString(), schoolID: this.selectedClass.schoolID }

        if (event && event === INVITE_STUDENTS || event === INVITE_COTEACHERS) {
          const dialogRef = this.dialog.open(InviteComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            this.action.setValue('')
            if (result) {
              this.snackbar.openSnackbar('Invitation Sent Successfully')
            }
            if (event && event === INVITE_STUDENTS) {
              this.studentList = [];
              this.specificClassService.getStudents(this.selectedClass.id);
            } else if (event && event === INVITE_COTEACHERS) {
              this.coTeachers = [];
              this.specificClassService.getCoTeachers(this.selectedClass.id);
            }
          })
        } else if (event && event === INVITE_POST_ASSIGNMENT) {
          const dialogRef = this.dialog.open(PostAssignmentComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            this.action.setValue('');
            if (result) {
              this.posts = [];
              this.specificClassService.getPosts(this.selectedClass.id);
            }
          })
        } else if (event && event === INVITE_POST_MATERIAL) {
          const dialogRef = this.dialog.open(PostMaterialComponent, dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            this.action.setValue('');
            if (result) {
              this.posts = [];
              this.specificClassService.getPosts(this.selectedClass.id);
            }
          })
        } else if (event && event === INVITE_TAKE_TEST) {
          let dialogProps = { ...dialogConfig };
          this.getTestSource(dialogProps);
          this.action.setValue('');
        } else if (event && event === CREATE_LIVE_CLASS) {
          let dialogProps = { ...dialogConfig };
          dialogProps.width = DIALOG_LIVE_CLASS_WIDTH;
          dialogProps.data = { classID: this.classID };
          const dialogRef = this.dialog.open(ScheduleLiveClassComponent, dialogProps);
          dialogRef.afterClosed().subscribe(result => {
            this.action.setValue('');
            if (result) {
              if (this.selectedDate === 'This Week') {
                this.weekRange = this.dateService.getWeekRange();
                this.specificClassService.getWeeklyLiveClasses(this.weekRange.monday, this.weekRange.saturday, this.classID);
                return;
              }
              this.specificClassService.getLiveClasses(this.classID, this.selectedDate);
            }
          });
        }
      });
  }
  getLabel(label) {
    return this.labelData[label.replace(/[- \s]/g, '').toLowerCase()]
  }
  getTestSource(dialogProps) {
    dialogProps.width = '50%';
    dialogProps.height = '32%';
    dialogProps.disableClose = false;
    const dialogRef = this.dialog.open(TestSourceAlertComponent, dialogProps);
    dialogRef.afterClosed().subscribe(res => {
      if (res && res !== 'copy') {
        dialogProps.width = DIALOG_WIDTH;
        dialogProps.height = '100%';
        const dialogRef = this.dialog.open(TestComponent, dialogProps)
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.posts = [];
            this.specificClassService.getPosts(this.selectedClass.id);
          }
        })
      } else if (res) {
        dialogProps.width = '91%';
        dialogProps.height = '73%';
        dialogProps.data = { fileDisabled: true, userType: USER_TYPES.teacher }
        const dialog = this.dialog.open(UploadFromLibraryComponent, dialogProps);
        dialog.afterClosed().subscribe(res => {
          if (res) {
            dialogProps.data = { test: res[0] };
            dialogProps.height = '100%';
            const dialogRef = this.dialog.open(TestComponent, dialogProps)
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.posts = [];
                this.specificClassService.getPosts(this.selectedClass.id)
              }
            });
          }
        })
      } else {
        return;
      }
    });
  }

  back() {
    this.sidenavService.maximizeSidenav();
    this.router.navigate(['../../', 'classes'], { relativeTo: this.route });
  }

  onSortChange(event: 'asc' | 'desc') {
    this.tableService.setSortDirection(event);
  }

  onDateSelect(date) {
    if (date === 'this-week') {
      this.selectedDate = 'This Week';
      this.weekRange = this.dateService.getWeekRange();
      this.specificClassService.getWeeklyLiveClasses(this.weekRange.monday, this.weekRange.saturday, this.classID);
      return;
    } else {
      this.selectedDate = date;
    }
    this.specificClassService.getLiveClasses(this.classID, date.toLowerCase());
  }

  tabSelect(event) {
    const tab = this.tabs[event.index];
    if (tab.label === POSTS) {
      this.posts.length === 0 ? this.specificClassService.getPosts(this.selectedClass.id) : null;

      this.specificClassService.post$.pipe(
        takeUntil(this.destroy$$),
      ).subscribe(post => {
        this.posts = post;
        this.sortDirection = this.tableService.getSortDirection();
      })
    } else if (tab.label === STUDENTS) {
      this.studentList.length === 0 ? this.specificClassService.getStudents(this.selectedClass.id) : null;

      this.specificClassService.students$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(student => {
          this.studentList = student;
        })

    } else if (tab.label === CO_TEACHERS) {
      this.coTeachers.length === 0 ? this.specificClassService.getCoTeachers(this.selectedClass.id) : null;

      this.specificClassService.coTeachers$.pipe(
        takeUntil(this.destroy$$),
      )
        .subscribe(teacher => {
          this.coTeachers = teacher;
        });
    } else if (tab.label === LIVE_CLASS) {
      if (!this.liveClass || this.liveClass.length === 0) {
        if (this.selectedDate === 'This Week') {
          this.weekRange = this.dateService.getWeekRange();
          this.specificClassService.getWeeklyLiveClasses(this.weekRange.monday, this.weekRange.saturday, this.classID);
          return;
        }
        this.specificClassService.getLiveClasses(this.classID, this.selectedDate);
      }
    }
  }

  onActionSelected(action: actionType) {
    this.posts = null;
    this.specificClassService.getPosts(this.selectedClass.id);
  }

  ngOnDestroy() {
    this.specificClassService.clearList();
  }
}
