import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { MatSort, MatSortable, Sort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { Posts } from 'src/app/models/post.models';
import { dateFormat, pageSizeOptions, paginatorPageSize } from 'src/app/Constants/Constant';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ClassSpecificService } from '../services/class-specific.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PostMaterialComponent } from '../post-material/post-material.component';
import { PostAssignmentComponent } from '../post-assignment/post-assignment.component';

const EDIT_CONTENT = 'Edit Content';
const DELETE_CONTENT = 'Delete Content';
const ARCHIVE = 'Archive';

@Component({
  selector: 'app-post-table',
  templateUrl: './post-table.component.html',
  styleUrls: ['./post-table.component.scss']
})
export class PostTableComponent implements OnInit, OnChanges {
  pageSizeOptions: number[] = pageSizeOptions;
  pageSize = paginatorPageSize;
  @Input() displayedColumns: string[];
  @Input() transactions: Posts[];
  @Output() actionSelected = new EventEmitter();
  @Input() sortDirection: 'asc' | 'desc';
  @Output() getSortDirection = new EventEmitter();
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  selection = new SelectionModel(true, []);
  dataSource: any;
  sortedData: any;
  dateFormat = '';

  menuListItems = [EDIT_CONTENT, DELETE_CONTENT];

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public specificClassService: ClassSpecificService,
    public cd: ChangeDetectorRef,
    public snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.dateFormat = dateFormat;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.transactions);
    this.cd.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.sort.sort(({ id: 'date', start: this.sortDirection }) as MatSortable);
    this.dataSource.sort = this.sort;
    this.sort.disableClear = true;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
  sortData(sort: Sort) {
    const data = this.transactions.slice();
    if (sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.getSortDirection.emit(sort.direction);
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return this.compare(a.date, b.date, isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sendMsg() {
    console.log(this.dataSource)
  }

  sendActivity(row: Posts) {
    if (row.activityType === 'tests') {
      this.router.navigate(['teacher', 'dashboard', 'class', 'test', row.activityID])
      return;
    }
    this.router.navigate(['teacher', 'dashboard', 'post-details', row.activityType, row.activityID]);
  }

  selectMenuItem(action, row: Posts) {
    if (action === DELETE_CONTENT) {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: { theme: 'teacher' },
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe((isDeleted: boolean) => {
        if (!!isDeleted) {
          let deletePost;
          if (row.activityType === 'materials') {
            deletePost = this.specificClassService.deleteMaterialPost(row.activityID);
          } else if (row.activityType === 'assignments') {
            deletePost = this.specificClassService.deleteAssignmentPost(row.activityID);
          } else {
            deletePost = this.specificClassService.deleteTest(row.activityID);
          }
          deletePost.subscribe(
            (res) => {
              this.snackbar.openSnackbar('post is succesfully deleted');
              this.actionSelected.emit();
            },
            (err) => {
              this.snackbar.openSnackbar(err.error.error);
            }
          );
        }
      });
    } else if (action === EDIT_CONTENT) {
      const classID = this.router.url.split('/').slice(-1)[0];
      const dialogData = { classID, post: row };
      if (row.activityType === 'tests') {
        this.snackbar.openSnackbar('Not yet integrated!!')
        return;
      }

      const dialogConfig = {
        width: '46%',
        height: '100%',
        backdropClass: 'blur',
        data: dialogData,
        disableClose: true,
        panelClass: ['teacher-theme', 'width-class'],
        autoFocus: false
      };
      const dialogRef = row.activityType === 'materials' ? this.dialog.open(PostMaterialComponent, dialogConfig) :
        this.dialog.open(PostAssignmentComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        (res) => {
          if (res === 'true') {
            this.actionSelected.emit();
          }
        });
    }
  }

}
