import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LiveClassModel } from '../models/live-class-models';
import { CrudService } from './crud.service';
import { LocalStorageService } from './local-storage.service';
import { SnackbarService } from './snackbar.service';

const TODAY = 'today'
const WEEK = 'this-week'

@Injectable({
  providedIn: 'root'
})
export class LiveClassService {
  private weeklyClass$$ = new BehaviorSubject<LiveClassModel[]>(null);
  private pastClass$$ = new BehaviorSubject<LiveClassModel[]>(null);
  private todayClass$$ = new BehaviorSubject<LiveClassModel[]>(null);
  private isLoading$$ = new BehaviorSubject<boolean>(true);

  public get todayClass$() {
    return this.todayClass$$.asObservable();
  }

  public get pastClass$() {
    return this.pastClass$$.asObservable();
  }

  public get weeklyClass$() {
    return this.weeklyClass$$.asObservable();
  }

  public get isLoading$() {
    return this.isLoading$$.asObservable();
  }

  public joinRoute(classID) {
    return environment.socketServerBaseUrl + `/class/live/${classID}/join`;
  }

  constructor(public crud: CrudService, public localStorage: LocalStorageService, public snackBar: SnackbarService) { }

  public liveClassRoute(dayFilter) {
    const studentID = this.localStorage.getItem('studentID');
    return studentID ? environment.apiUrl + `class/schedule/student/${studentID}?period=${dayFilter}` : environment.apiUrl + `class/schedule?period=${dayFilter}`;
  }

  public weeklyLiveClassRoute(startDate: Date, endDate: Date) {
    const start = startDate.toISOString();
    const end = endDate.toISOString();
    const studentID = this.localStorage.getItem('studentID');
    return studentID ? environment.apiUrl + `class/schedule/student/${studentID}?period=weekly&start=${start}&end=${end}`
      : environment.apiUrl + `class/schedule?period=weekly&start=${start}&end=${end}`;
  }

  public injectToClass(dayFilter, classes) {
    if (dayFilter === TODAY) {
      this.todayClass$$.next(classes);
    } else if (dayFilter === WEEK) {
      this.weeklyClass$$.next(classes);
    } else {
      this.pastClass$$.next(classes);
    }
    this.isLoading$$.next(false);
  }

  public liveClassDetails(dayFilter) {
    this.isLoading$$.next(true);
    return this.crud.get(this.liveClassRoute(dayFilter)).subscribe(
      (res) => {
        this.injectToClass(dayFilter, res.schedule);
        if (res.error != "") {
          this.snackBar.openSnackbar(res.error);
        }
      },
      (err) => {
        this.injectToClass(dayFilter, err);
        this.isLoading$$.next(false);
      }
    );
  }

  public weeklyLiveClassDetails(startDate: Date, endDate: Date) {
    this.isLoading$$.next(true);
    return this.crud.get(this.weeklyLiveClassRoute(startDate, endDate)).subscribe(
      (res) => {
        this.weeklyClass$$.next(res.schedule);
        if (res.error !== '') {
          this.snackBar.openSnackbar(res.error);
        }
        this.isLoading$$.next(false);
      },
      (err) => {
        this.weeklyClass$$.next([]);
        this.snackBar.openSnackbar(err.error.error);
        this.isLoading$$.next(false);
      }
    );
  }

  public clearLiveClassData() {
    this.weeklyClass$$.next([]);
    this.pastClass$$.next([])
    this.todayClass$$.next([])
  }

  public joinClass(classID) {
    return this.crud.get(this.joinRoute(classID))
  }
}
