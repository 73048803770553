import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
import { FormDataService } from 'src/app/services/form-data.service';
import { CourseActivity } from 'src/app/models/course-activity.model';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FILE_TYPES } from 'src/app/Constants/Constant';
import { jsPDF } from 'jspdf';


const questionKey = 'uploadedFiles';
const questionFile = 'questions.json';
const answerFile = 'answers.json';
const answerKey = 'ansFiles';

@Injectable({
  providedIn: 'root'
})

export class CourseActivitiesService {

  constructor(
    private crud: CrudService,
    private formData: FormDataService,
    private snackbar: SnackbarService,
  ) { }

  private isLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get isLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  private selectedCourseActivity$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get selectedCourseActivity$(): Observable<any> {
    return this.selectedCourseActivity$$.asObservable();
  }

  private courseActivities$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public get courseActivities$(): Observable<CourseActivity[]> {
    return this.courseActivities$$.asObservable();
  }


  private activitySubmissionDetails$$ = new BehaviorSubject(null);
  private files$$ = new BehaviorSubject([]);

  public get activitySubmissionDetails$() {
    return this.activitySubmissionDetails$$.asObservable();
  }

  public get files$() {
    return this.files$$.asObservable();
  }

  private currentSlide$$ = new BehaviorSubject('1');

  private updatedFiles$$ = new BehaviorSubject([]);

  public get updatedFiles$() {
    return this.updatedFiles$$.asObservable();
  }

  public get currentSlide$(){
    return this.currentSlide$$.asObservable();
  }

  private courseActivityRoute() {
    return environment.apiUrl + 'course-activity';
  }


  // for materials and assignments
  public updateCourseActivity = (activityID, body) => {
    delete body.courseID;
    const formData = this.formData.createFormData(body);
    return this.crud.patch(`${this.courseActivityRoute()}/edit/${activityID}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // for materials and assignments
  public postCourseActivity = (body, questions?, answers?) => {
    const formData = questions ? this.createTestFormData(body, questions, answers) : this.formData.createFormData(body);
    return this.crud.post(this.courseActivityRoute(), formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public reorderCourseActivity(courseID, body) {
    return this.crud.patch(`${this.courseActivityRoute()}/reorder/${courseID}`, body);
  }

  private createTestFormData(body, questions, answers) {
    const questionJSON = this.createJSONFile(questions, questionFile);
    const answerJSON = this.createJSONFile(answers, answerFile);
    const file = new FormData();
    Object.keys(body).forEach(ele => {
      file.append(ele, body[ele]);
    });
    file.append(questionKey, questionJSON);
    file.append(answerKey, answerJSON);
    return file;
  }

  private createJSONFile(data, fileName) {
    data = JSON.stringify(data);
    const blob = new Blob([data], { type: 'application/json' });
    const file2 = new File([blob], fileName);
    console.log(file2)
    return file2;
  }

  public getCourseActivities(courseID: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/course/${courseID}`).subscribe((resp) => {
      if (resp) {
        this.courseActivities$$.next(resp);
        this.isLoading$$.next(false);
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch posts');
      this.isLoading$$.next(false);
    });
  }

  public getCourseActivity(activityId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/details/${activityId}`).subscribe((resp) => {
      if (resp) {
        this.selectedCourseActivity$$.next(resp);
        this.isLoading$$.next(false);
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch course activity details');
    });
  }

  public getSubmission(activityId: string, studentId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/submission/${activityId}/student/${studentId}`).subscribe((resp) => {
      if (resp) {
        this.isLoading$$.next(false);
        this.activitySubmissionDetails$$.next(resp);
        this.files$$.next(
          resp.submission.files.map((file, index) => {
            return {
              name: file,
              url: resp.submissionURLs[index]
            };
          })
        );
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch student submission');
    });
  }

  public viewEvaluation(activityId: string, studentId: string) {
    this.isLoading$$.next(true);
    this.crud.get(environment.apiUrl + `course-activity/evaluation/${activityId}/student/${studentId}`).subscribe((resp) => {
      if (resp) {
        this.isLoading$$.next(false);
        this.activitySubmissionDetails$$.next(resp);
        this.files$$.next(
          resp.evaluation.files.map((file, index) => {
            return {
              name: file,
              url: resp.evalURLs[index]
            };
          })
        );
      }
    }, (err) => {
      this.snackbar.openSnackbar('unable to fetch student submission');
    });
  }


  public updateSlide(slideNumber: number) {
    this.currentSlide$$.next(`${slideNumber + 1}`);
  }

  public downloadAllUpdatedFiles() {
    const files = this.files$$.value;
    files.forEach(file => {
      saveAs(file.url, file.name);
    });
  }

  updateFiles(file, fileName, type) {
    const files = this.updatedFiles$$.value;
    const selectedFileIndex = files.findIndex(updatedFile => updatedFile.name === fileName);
    if (selectedFileIndex > -1) {
      files[selectedFileIndex].file = file;
    } else {
      files.push({
        name: fileName,
        type,
        file
      });
    }
    this.updatedFiles$$.next(files);
  }

  public async getUpdatedFiles() {
    const files = this.updatedFiles$$.value;
    const updatedFiles = [];
    for (const file of files) {
      const extension = file.name.split('.');
      if (extension[extension.length - 1] !== 'pdf') {
        let s = '';
        for (let i = 0; i < extension.length - 1; i++) {
          s = s.concat(extension[i]);
        }
        s = s.concat('.png');
        file.name = s;
      }
      if (file.type === FILE_TYPES.IMAGE) {
        const blob: any = await new Promise(resolve => file.file.toBlob(resolve));
        const fileObject = new File([blob], file.name)
        updatedFiles.push(fileObject);
      } else if (file.type === FILE_TYPES.PDF) {
        const pdf = new jsPDF({
          unit: 'px',
          format: 'a4',
        });
        Array.from(file.file.children).forEach((el: any, index) => {
          const imgData = el.toDataURL('image/png')
          if (index > 0) {
            pdf.addPage(imgData.width, imgData.height);
          }
          pdf.addImage(imgData, 'PNG', 0, 0, imgData.width, imgData.height);
        });
        var blob = pdf.output('blob');
        const fileName = new File([blob], file.name);
        updatedFiles.push(fileName);
      }
    };
    return updatedFiles;
  }

  public evaluateActivity(body) {
    const formData = this.formData.createFormData(body);
    return this.crud.post(environment.apiUrl + 'course-activity/evaluation', formData);
  }

  public reset() {
    this.isLoading$$.next(true);
    this.selectedCourseActivity$$.next(null);
    this.courseActivities$$.next(null);
    this.activitySubmissionDetails$$.next(null);
    this.files$$.next([]);
    this.currentSlide$$.next('1');
    this.updatedFiles$$.next([]);
  }
}
