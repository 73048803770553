import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ClassDetailComponent } from '../class-detail/class-detail.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { minDate, COURSE_ACTIVITY, ACTIVITY_TYPES, UPDATE_ACTION } from 'src/app/Constants/Constant';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { PostAssignmentService } from '../services/post-assignment.service';
import { from, Subject } from 'rxjs';
import { concatMap, delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import { PostDetailService } from '../services/post-detail.service';
import { Posts } from 'src/app/models/post.models';
import { CourseActivitiesService } from 'src/app/admin/services/course-activities.service';
import { HttpEventType } from '@angular/common/http';

export interface DialogDataModel {
  id?: string;
  classID?: string;
  post?: Posts;
  courseID: string;
}

const EDIT = 'edit'
const POST = 'post'

@Component({
  selector: 'app-post-material',
  templateUrl: './post-material.component.html',
  styleUrls: ['./post-material.component.scss']
})
export class PostMaterialComponent implements OnInit {
  materialForm: FormGroup;
  docUrl: any;
  docFiles = [];
  isdocUploaded: boolean;
  uploadFromLibrary: boolean = false;
  minDate: Date;
  maxDate: Date = null;
  classID: string
  mapped_url = [];
  isUploading: boolean = false;
  textHeader: 'edit' | 'post' = POST;
  disable: boolean = false;
  checked = false;
  private destroy$$: Subject<any> = new Subject<any>();
  public entityType: string;
  public courseActivityEntityType: string = COURSE_ACTIVITY;
  public isCourseActivity = false;
  public isEditAction = false;
  progress = 0;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ClassDetailComponent>,
    public snackbarService: SnackbarService,
    public materialService: PostDetailService,
    public postService: PostAssignmentService,
    private courseActivitiesService: CourseActivitiesService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.entityType = this.data.entityType;
    this.isCourseActivity = this.entityType === this.courseActivityEntityType;
    this.classID = this.data.id ? this.data.id : this.data.classID;
    this.minDate = minDate;
    this.dialogRef.updatePosition({ right: `0px` });
    this.data.post ? this.populateForm() : this.buildForm();
    this.isEditAction = this.textHeader === EDIT;
  }

  populateForm() {
    this.textHeader = EDIT;
    const posts = this.data.post;
    posts.files.map(file => {
      const fileName = new File([''], file);
      this.docFiles.push(fileName);
    })
    this.docUrl = this.docFiles;
    this.isdocUploaded = true;

    this.fillForm(posts);
    if (new Date() >= new Date(posts.dueDate)) {
      this.materialForm.disable();
      this.disable = true;
      return;
    }
  }

  buildForm() {
    this.fillForm(null);
  }

  fillForm(post) {
    if (this.isCourseActivity) {
      this.materialForm = this.fb.group({
        title: [post ? post.title : null, [Validators.required]],
        note: [post ? post.description : null, [Validators.required]],
      });
    } else {
      this.materialForm = this.fb.group({
        title: [post ? post.title : null, [Validators.required]],
        note: [post ? post.description : null, [Validators.required]],
        dueDate: [post ? post.dueDate : null, [Validators.required]],
      });
    }
    this.checked = this.data.post ? this.data.post.saveToLibrary : false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addEvent(event) {
    this.materialForm.controls['dueDate'].setValue(event.value);
  }

  onDocUpload(urlList) {
    this.docUrl = urlList.files;
    this.uploadFromLibrary = urlList.uploadFromLibrary;
    this.isdocUploaded = urlList.files.length > 0 ? true : false;
  }

  checkForValidation() {
    if (!this.materialForm.controls['title'].value) {
      this.materialForm.controls['title'].setErrors({ 'valid': false });
      this.materialForm.controls['title'].markAllAsTouched();
    }
    if (!this.materialForm.controls['note'].value) {
      this.materialForm.controls['note'].setErrors({ 'valid': false });
      this.materialForm.controls['note'].markAllAsTouched();
    }
    if (this.materialForm.controls['dueDate'] && !this.materialForm.controls['dueDate'].value) {
      this.materialForm.controls['dueDate'].setErrors({ 'valid': false })
      this.materialForm.controls['dueDate'].markAllAsTouched();
    }
  }

  onCheckboxClick(event) {
    this.checked = !this.checked;
  }

  getLibraryFilesBody(requestBody, callback?) {
    const fileNames = [];
    const filePaths = [];

    this.docUrl.map(file => {
      fileNames.push(file.name);
      filePaths.push(file.path);
    });
    requestBody.fileNames = fileNames;
    requestBody.filePaths = filePaths;
    if (callback) {
      requestBody.uploadFromCloud = true;
      return callback(requestBody);
    } else {
      requestBody.saveToLibrary = false;
      requestBody.uploadFromLibrary = true;
      requestBody.classID = this.classID;
    }
    return this.postService.postmaterial(requestBody);
  }

  getLocalFilesBody(requestBody, callback?) {
    let postRequest;
    let removedFile = [];
    let addedFile = [];
    const urlFiles = [];

    if (this.textHeader === EDIT) {
      this.data.post.files.map(file => {
        const fileName = new File([''], file);
        urlFiles.push(fileName);
      })

      urlFiles.map(file => {
        if (this.docUrl.filter(url => url.name === file.name).length === 0) {
          removedFile.push(file.name)
        }
      })
      addedFile = this.docUrl.filter(url => urlFiles.filter(file => file.name === url.name).length === 0);
      requestBody.uploadedFiles = addedFile.length === 0 ? [] : addedFile;
      requestBody.deletedFiles = removedFile.length === 0 ? [] : removedFile;
      if (callback) {
        requestBody.uploadFromCloud = false;
        postRequest = callback(this.data.post.id, requestBody);
      } else {
        requestBody.uploadFromLibrary = false;
        postRequest = this.postService.editMaterial(this.data.post.activityID, requestBody);
      }
    } else {
      requestBody.uploadedFiles = this.docUrl;
      if (callback) {
        requestBody.uploadFromCloud = false;
        postRequest = callback(requestBody);
      } else {
        requestBody.uploadFromLibrary = false;
        requestBody.classID = this.classID;
        requestBody.saveToLibrary = this.checked;
        postRequest = this.postService.postmaterial(requestBody);
      }
    }
    return postRequest;
  }

  postMaterial() {
    this.checkForValidation();
    if (!this.docUrl) {
      this.isdocUploaded = false;
      return;
    }
    if (this.materialForm.valid && this.docUrl) {
      const dialog = this.dialog.open(DeleteDialogComponent, {
        width: '363px',
        height: '190px',
        backdropClass: 'blur',
        data: {
          theme: this.isCourseActivity ? 'admin' : 'teacher',
          isUpload: true,
          alertText: this.textHeader === EDIT ? 'Are you sure you want to update this material ?' : null,
          actionType: this.textHeader === EDIT ? UPDATE_ACTION : null,
        },
        panelClass: ['delete-dialog-css'],
        autoFocus: false
      });
      dialog.afterClosed().pipe(
        takeUntil(this.destroy$$)
      ).subscribe(isUploaded => {
        if (isUploaded === false) {
          return;
        } else if (this.isCourseActivity) {
          this.isUploading = true;
          this.materialForm.disable();

          const requestBody = {
            title: this.materialForm.controls.title.value,
            courseID: this.data.courseID,
            description: this.materialForm.controls.note.value,
            activityType: ACTIVITY_TYPES.materials,
            index: this.data.activityIndex,
          };
          let postRequest: any;
          if (this.uploadFromLibrary) {
            postRequest = this.getLibraryFilesBody(requestBody, this.courseActivitiesService.postCourseActivity);
          } else {
            postRequest = this.textHeader === EDIT ?
              this.getLocalFilesBody(requestBody, this.courseActivitiesService.updateCourseActivity) :
              this.getLocalFilesBody(requestBody, this.courseActivitiesService.postCourseActivity);
          }
          postRequest.pipe(
            takeUntil(this.destroy$$)
          ).subscribe(
            (resp) => {
              if (resp.type === HttpEventType.Response) {
                if (this.textHeader === EDIT) {
                  this.snackbarService.openSnackbar('successfully updated the material');
                } else {
                  this.snackbarService.openSnackbar('successfully uploaded the material');
                }
                this.dialogRef.close({ material: resp.body });
              }

              if (resp.type === HttpEventType.UploadProgress) {
                this.progress = 100 * resp.loaded / resp.total;
              }
            },
            (error) => {
              this.materialForm.enable();
              this.docFiles = this.docUrl;
              this.snackbarService.openSnackbar(error.error.error ? error.error.error : 'unable to upload, please try again');
              this.isUploading = false;
              this.progress = 0;
            }
          );
        } else {
          this.isUploading = true;
          this.materialForm.disable();
          const requestBody = {
            title: this.materialForm.controls['title'].value,
            dueDate: new Date(this.materialForm.controls['dueDate'].value).toISOString(),
            description: this.materialForm.controls['note'].value,
            uploadedFiles: this.docUrl,
          };
          let postRequest;

          if (this.uploadFromLibrary) {
            postRequest = this.getLibraryFilesBody(requestBody);
          } else {
            postRequest = this.getLocalFilesBody(requestBody);
          }

          postRequest.pipe(
            takeUntil(this.destroy$$)
          ).subscribe(
            (resp) => {
              if (resp.type === HttpEventType.Response) {
                this.snackbarService.openSnackbar('successfully uploaded the materials');
                this.dialogRef.close('true');
              }
              if (resp.type === HttpEventType.UploadProgress) {
                this.progress = 100 * resp.loaded / resp.total;
              }
            },
            (error) => {
              this.materialForm.enable();
              this.docFiles = this.docUrl;
              this.snackbarService.openSnackbar(error.error.error ? error.error.error : 'unable to upload, please try again');
              this.isUploading = false;
              this.progress = 0;
            }
          );
        }
      });
      this.isUploading = false;
      return;
    }
  }
}
